const theme = {
  light: {
    backgroundColor: "var(--keithyang-colors-background-0, #ffffff)",
    headerBoxShadow: "0 0px 12px -6px rgba(0, 0, 0, 0.2)",
    colors: {},
    fontColor: "var(--keithyang-colors-typeface-0, #000000)",
    foregroundColor: "var(--keithyang-colors-body-1, #f6f6f6)",
    imageBoxShadow: "0 10px 20px -4px rgba(0, 0, 0, 0.5)",
    transitionTime: 0.5,
  },
  dark: {
    backgroundColor: "var(--keithyang-colors-background-0, #1a1a1a)",
    headerBoxShadow: "0px 10px 6px rgba(0, 0, 0, 0.15)",
    colors: {},
    fontColor: "var(--keithyang-colors-typeface-0, #eeeeee)",
    foregroundColor: "var(--keithyang-colors-body-1, #0a0a0a)",
    imageBoxShadow: "0 10px 20px -4px rgba(0, 0, 0, 0.5)",
    transitionTime: 0.5,
  },
}

export default theme
