import React, { createContext, useContext, useEffect, useState } from "react"
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react"
import theme from "../theme"

const KEY = "mode"

const defaultContextData = {
  dark: false,
  toggleDark: () => {},
}

export const ThemeContext = createContext(defaultContextData)

const useTheme = () => useContext(ThemeContext)

const storage = {
  get: () => window.localStorage.getItem(KEY),
  set: value => window.localStorage.setItem(KEY, value),
}

const supportsDarkMode = () =>
  window.matchMedia("(prefers-color-scheme: dark)").matches === true

const useDarkMode = () => {
  const [themeState, setThemeState] = useState("light")

  const setThemeStateEnhanced = () => {
    setThemeState(prevState => {
      const nextState = prevState === "light" ? "dark" : "light"
      document.body.classList.remove("keithyang-" + prevState)

      document.body.classList.add("keithyang-" + nextState)
      storage.set(nextState)

      return nextState
    })
  }

  useEffect(() => {
    const storedMode = storage.get()
    if (!storedMode && supportsDarkMode()) {
      return setThemeStateEnhanced()
    }

    if (!storedMode || storedMode === themeState) {
      return
    }
    setThemeStateEnhanced()
  }, [themeState])

  return [themeState, setThemeStateEnhanced]
}

const ThemeProvider = ({ children }) => {
  const [themeState, setThemeStateEnhanced] = useDarkMode()
  const themeLoaded = themeState === "dark" ? theme.dark : theme.light
  const toggleDark = () => {
    setThemeStateEnhanced(themeState === "light" ? "dark" : "light")
  }

  return (
    <EmotionThemeProvider theme={themeLoaded}>
      <ThemeContext.Provider
        value={{
          dark: themeState === "dark",
          toggleDark,
        }}
      >
        {children}
      </ThemeContext.Provider>
    </EmotionThemeProvider>
  )
}

export { ThemeProvider, useTheme }
