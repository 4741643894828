import React from "react"
import GlobalStyles from "../components/globalStyles"
import { ThemeProvider } from "./themeContext"

const RootWrapper = ({ children }) => (
  <ThemeProvider>
    <GlobalStyles />
    {children}
  </ThemeProvider>
)

export default RootWrapper
