import React from "react"
import { css, Global, withTheme } from "@emotion/react"

const GlobalStyles = withTheme(props => {
  return (
    <Global
      styles={css`
        *,
        *:before,
        *:after {
          box-sizing: border-box;
          font-family: "Helvetica Neue", sans-serif;
        }

        body {
          margin: 0;
          background: ${props.theme.backgroundColor};
        }

        .keithyang-light {
          --keithyang-colors-body-0: #ffffff;
          --keithyang-colors-typeface-0: #000000;
          --keithyang-colors-body-1: #f6f6f6;
        }
        .keithyang-dark {
          --keithyang-colors-body-0: #1a1a1a;
          --keithyang-colors-typeface-0: #eeeeee;
          --keithyang-colors-body-1: #0a0a0a;
        }
      `}
    />
  )
})

export default GlobalStyles
